<template>
  <div class="pageTable p-10">
    <slot name="top"></slot>
    <search-group
      v-if="searchs"
      :searchs="searchs"
      :formInitData="formInitData"
      @search="getSearchParams"
    >
    </search-group>
    <div class="pb-10">
      <el-button
        v-for="btn in btns"
        :key="btn.name"
        size="mini"
        v-bind="btn.config"
        @click="btn.click({ selectList })"
        >{{ btn.name }}</el-button
      >
    </div>
    <slot name="center"></slot>

    <el-table
      :data="tableData"
      height="calc(74vh - 43px)"
      border
      style="width: 100%"
      @selection-change="selectionChange"
      v-loading="loading"
    >
      <el-table-column
        v-for="(column, index) in tableColumn"
        :key="column.label"
        v-bind="column"
      >
        <template
          v-slot="scope"
          v-if="column.btns"
        >
          <div class="dp-flex">
            <el-button
              v-for="btn in column.btns"
              :key="btn.text"
              v-bind="btn"
              v-show="!(btn.hidden && btn.hidden(scope))"
              @click="btn.click && btn.click({ scope, selectList })"
              >{{ btn.text }}</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px; text-align: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.pageNumber + 1"
        :page-sizes="[20, 40, 80, 100]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>
<script>
import SearchGroup from './SearchGroup.vue';

export default {
  components: {
    SearchGroup,
  },
  props: {
    searchs: Array, // 顶部搜索条件
    // 中部按钮
    btns: {
      default: () => [],
      type: Array,
    },
    // 同el-table-column
    tableColumn: {
      default: () => [],
      type: Array,
    },
    // 格式化参数
    formatterParams: {
      default: (val) => {
        return val;
      },
      type: Function,
    },
    formatterTableData: {
      default: (val) => {
        return val;
      },
      type: Function,
    },
    // 初始化请求的数据
    formInitData: {
      type: Object,
      default: () => ({}),
    },
    // 是否立即请求接口 仅第一次生效
    immediateGetList: {
      type: Boolean,
      default: true,
    },
    API: {
      type: Function,
      default: async () => {
        return Promise.resolve();
      },
    },
    isNeedFormatData: {
      // 是否需要转化接口
      type: Boolean,
    },
    pageNumberStart: {
      // pageNumber默认其实页
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tableData: [],
      params: null,
      pageTotal: 0,
      pagination: {
        pageNumber: 0,
        pageSize: 20,
      },
      selectList: [],
      alreadyInitList: false,
      loading: false,
    };
  },
  watch: {
    immediateGetList: {
      handler(val) {
        if (val && !this.alreadyInitList) {
          this.alreadyInitList = val;
          this.getList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pagination = {
        ...this.pagination,
        pageSize: val,
        pageNumber: 0,
      };
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination = {
        ...this.pagination,
        pageNumber: val - 1,
      };
      this.getList();
    },
    // 点击搜索时获取搜索条件
    getSearchParams(params) {
      this.params = params;
      this.pagination = {
        ...this.pagination,
        pageNumber: 0,
      };
      this.getList();
    },
    // 获取列表
    getList(option) {
      if (!this.alreadyInitList) return;
      const params = {
        ...(this.params || this.formInitData),
        ...this.pagination,
        ...option,
        pageNumber: this.pagination.pageNumber + this.pageNumberStart,
      };
      const lastParams = this.formatterParams(params);
      this.loading = true;
      // todo
      this.API(lastParams)
        .then((res) => {
          if (res.code == 0) {
            let _res = res;
            if (this.isNeedFormatData) {
              _res = this.formatData(res);
            }
            this.tableData = _res.data.content;
            this.pageTotal = _res.data.totalElements;
            this.pagination = {
              pageNumber: _res.data.pageable.pageNumber - this.pageNumberStart,
              pageSize: _res.data.pageable.pageSize,
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 格式化接口返回
    formatData(res) {
      return {
        data: {
          content: res.data.contents,
          pageable: {
            pageNumber: res.data.paging.page_number,
            pageSize: res.data.paging.page_size,
          },
          totalElements: res.data.paging.total_count,
        },
      };
    },
    //选择改变
    selectionChange(selection) {
      this.selectList = selection;
      this.$emit('selectionChange', selection);
    },
  },
  created() {
    console.log('----created');
  },
  mounted() {
    console.log('----mounted');
  },
};
</script>
<style lang="scss" scoped>
.pageTable {
}
</style>
