import request from '@/utils/request';

// 充值
export function accountRecharge(data) {
  return request({
    url: `/blueMedia/account_recharge`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 根据账户id获取对应的账单简称
export function findAgencyByAdAccountId(params) {
  return request({
    url: '/blueMedia/findAgencyByAdAccountId',
    params,
  });
}
