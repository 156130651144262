import request from '@/utils/request';

const mockUrl = ''; //'https://mock.apifox.cn/m1/2851801-0-default/api';
// 充值列表
export function queryOpenAccountPage(data) {
  return request({
    url: mockUrl + `/blueMedia/queryAccountRechargePage`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// bm、像素列表
export function queryAccountBindPage(data) {
  return request({
    url: mockUrl + `/blueMedia/queryAccountBindPage`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 账户绑定bm
export function accountBindBm(data) {
  return request({
    url: `/blueMedia/accountBindBm`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 账户绑定像素
export function accountBindPixelFb(data) {
  return request({
    url: `/blueMedia/accountBindPixel`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
